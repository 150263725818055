.fill {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: stretch;
}
.fill img {
  min-width: 100%;
  max-height: 400px;
}

.hirobaslik {
  padding: 0 10px;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 2px;
  color: antiquewhite;
  text-shadow: 2px 2px 2px #000, -2px -2px 2px #fff;
  z-index: 1;
}

.hiroimg {
  z-index: 0;
  width: 100%;
  filter: drop-shadow(5px 5px 10px #ccc);
}

@media (width <= 795px) {
  .hirobaslik {
    font-size: 2rem;
  }
}

@media (795px < width <= 1166px) {
  .hirobaslik {
    font-size: 2.5rem;
  }
}

.kedis {
  animation-name: kedii;
  animation-duration: 40s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes kedii {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(calc(-100% + 470px));
  }
}

.ortaln {
  animation-name: ortal;
  animation-duration: 60s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes ortal {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(calc(-100% + 470px));
  }
}

.altaln {
  animation-name: altal;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes altal {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(calc(-100% + 166px));
  }
}

.catwals {
  animation-name: catwa;
  animation-duration: 40s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  z-index: 0;
}

@keyframes catwa {
  0% {
    transform: translateX(0px) rotateY(180deg);
  }
  49% {
    transform: translateX(calc(100vw - 142px)) rotateY(180deg);
  }
  50% {
    transform: translateX(calc(100vw - 142px)) rotateY(0deg);
  }
  99% {
    transform: translateX(0px) rotateY(0deg);
  }
  100% {
    transform: translateX(0px) rotateY(180deg);
  }
}

.catwals2 {
  animation-name: catwa1;
  filter: opacity(0.5);
  animation-duration: 30s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  z-index: 0;
}

@keyframes catwa1 {
  0% {
    transform: translateX(0px) rotateY(180deg);
  }
  49% {
    transform: translateX(calc(100vw - 165px)) rotateY(180deg);
  }
  50% {
    transform: translateX(calc(100vw - 165px)) rotateY(0deg);
  }
  99% {
    transform: translateX(0px) rotateY(0deg);
  }
  100% {
    transform: translateX(0px) rotateY(180deg);
  }
}
