.hirologo {
  animation-name: donlo;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  /* animation-iteration-count: infinite;
  animation-direction: alternate; */
  z-index: 0;

  width: 100%;
  padding: 10px;
}

@keyframes donlo {
  0% {
    transform: translateX(100%) scaleX(0.3);
  }
  60% {
    transform: translateX(0%) scaleX(0.7);
  }
  100% {
    transform: translateX(0%) scaleX(1);
  }
}

.hiroyazi {
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 3px;
  color: white;
  text-shadow: -1px -1px 1px lightgray, 1px 1px 1px gray, 0px 0px 5px red;

  animation-name: donya;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 2.5s;
}

@keyframes donya {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.3);
  }
  100% {
    transform: scaleX(1);
  }
}

.kosullar .pl {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.card1 {
  width: 45%;
  box-sizing: border-box;
  margin: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 15px;
  font-size: larger;
  cursor: pointer;

  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.card-head {
  text-align: center;
  padding: 10px 0;
  letter-spacing: 1px;
  font-weight: 200;
  text-shadow: -1px -1px 1px lightgray, 1px 1px 1px gray, 0px 0px 5px white;
}

.card-body {
  padding: 10px 0;
  letter-spacing: 2px;

  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

.card1:hover .card-body {
  font-weight: 400;
  transform: scaleX(1.7) scaleY(1.3);
  transition: all 1s ease-in-out;
  color: blue;
}
