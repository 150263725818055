.uyeyaz {
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 3px;
  color: white;
  text-shadow: -1px -1px 1px red, 1px 1px 1px red, 0px 0px 5px red;

  animation-name: uyeyaz2;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

@keyframes uyeyaz2 {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(1.5) scaleX(3);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.uyeform {
  padding-right: 10px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}
.sagtrf {
  padding-left: 10px;
}

.uyeform label {
  width: 40%;
  text-align: right;
  padding-right: 10px;
}
.uyeform input {
  width: 60%;
  padding: 5px 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 5px 10px 20px 3px;
}
.uyeform input::placeholder {
  color: lightgray;
}

.uyeform input:focus-visible {
  background-color: lightcyan;
}

.uyeform .row {
  margin-top: 20px;
  justify-content: right;
}

.yanres {
  animation-name: yanres2;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes yanres2 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(calc(-100% + 600px));
  }
}

@media (width <= 350px) {
  .uyeyazgi {
    letter-spacing: 5px;
  }

  .uyeform {
    width: 95%;
  }

  .uyeform label {
    width: auto;
    margin-bottom: 10px;
  }

  .uyeform input {
    width: 90%;
    margin-bottom: 10px;
  }

  .rowkuc {
    flex-direction: column;
  }
}

@media (350px < width <= calc(1180px - 378px + 40px)) {
  .uyeform {
    width: 95%;
  }

  .uyeform label {
    width: auto;
  }

  .uyeform input {
    width: 70%;
  }
}

@media (842px < width <= calc(1180px + 100px)) {
  .uyeform {
    width: 95%;
  }
  .sagtrf {
    width: 95%;
  }

  .uyeform label {
    width: auto;
  }

  .uyeform input {
    width: 70%;
  }
}

@media (width >= calc(1180px + 100px)) {
  .uyeform {
    width: 60%;
  }
  .sagtrf {
    width: 40%;
  }

  .uyeform label {
    width: 40%;
  }

  .uyeform input {
    width: 60%;
  }
}

.altCiz {
  color: darkblue;
}
.altCiz:hover {
  color: blue !important;
}
