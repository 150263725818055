.ozelse {
  animation-name: ozel;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes ozel {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(calc(-50%));
  }
}

.logohrkt {
  animation-name: hrklg;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes hrklg {
  0% {
    transform: scale(calc(90%));
  }
  100% {
    transform: scale(calc(100%));
  }
}

#myUl {
  width: 100%;
}
.myLi {
  font-style: normal;
  transform: translateX(100%);
  list-style: none;
  text-shadow: 1px 1px 1px white, -1px -1px 1px black;
  letter-spacing: 0px;
  padding: 0px;
  border-bottom: 1px solid lightgray;
  color: lightgray;
  font-weight: 100;
}

.my-li {
  transform: translateX(0%);
  font-style: italic;
  letter-spacing: 3px;
  padding: 5px 0;
  color: red;
  font-weight: 500;
  transition: all 3s ease-in-out;
}

.lister {
  animation-name: lsthr;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  z-index: 0;
}

@keyframes lsthr {
  0% {
    filter: grayscale(0) contrast(1);
  }
  100% {
    filter: grayscale(1) contrast(2);
  }
}

.catwals {
  animation-name: catwa;
  animation-duration: 40s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  z-index: 0;
}

@keyframes catwa {
  0% {
    transform: translateX(0px) rotateY(180deg);
  }
  49% {
    transform: translateX(calc(100vw - 142px)) rotateY(180deg);
  }
  50% {
    transform: translateX(calc(100vw - 142px)) rotateY(0deg);
  }
  99% {
    transform: translateX(0px) rotateY(0deg);
  }
  100% {
    transform: translateX(0px) rotateY(180deg);
  }
}

.catwals2 {
  animation-name: catwa1;
  filter: invert(1);
  animation-duration: 30s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  z-index: 0;
}

@keyframes catwa1 {
  0% {
    transform: translateX(0px) rotateY(180deg);
  }
  49% {
    transform: translateX(calc(100vw - 142px)) rotateY(180deg);
  }
  50% {
    transform: translateX(calc(100vw - 142px)) rotateY(0deg);
  }
  99% {
    transform: translateX(0px) rotateY(0deg);
  }
  100% {
    transform: translateX(0px) rotateY(180deg);
  }
}

.basresm2 {
  height: 330px !important;
}

.renkYazi {
  font-size: 100px;
}

@media (width <= 800px) {
  .renkYazi {
    font-size: 50px;
  }
}
