.buton {
  display: inline-block;
  font-weight: 200;
  margin: 10px;
  letter-spacing: 2px;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
}

.buton:hover {
  background-color: rgb(255, 255, 0, 1);
  transition: 0.5s all;
}

.buton:active {
  border: 1px solid #aaa !important;
  box-shadow: 0px 0px 5px #aaa !important;
}
