@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}
