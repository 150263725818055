@import url("../fonts/Roboto/Roboto.css");

body {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
