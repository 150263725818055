.container {
  display: flex;
  flex-direction: column;
}

.icerik {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 300;
  text-shadow: 1px 1px 1px #bbb;
  padding: 20px 0 100px 0;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (width <= 350px) {
  .container {
    margin: 0 5px;
  }

  .icerik {
    font-size: 1.3rem;
  }

  .uyeform input,
  .uyeformgi input {
    font-size: 1.3rem;
  }
}

@media (350px < width <= calc(1180px - 378px + 40px)) {
  .container {
    margin: 0 20px;
    align-items: stretch;
  }

  .icerik {
    font-size: 1.3rem;
  }

  .uyeform input,
  .uyeformgi input {
    font-size: 1.3rem;
  }
}

@media (842px < width <= calc(1180px + 100px)) {
  .container {
    margin: 0 20px;
  }

  .icerik {
    font-size: 1.7rem;
  }

  .uyeform input,
  .uyeformgi input {
    font-size: 1.7rem;
  }
}

@media (width >= calc(1180px + 100px)) {
  .container {
    margin: 0 50px;
    align-items: stretch;
  }

  .icerik {
    font-size: 1.84rem;
  }

  .uyeform input,
  .uyeformgi input {
    font-size: 1.84rem;
  }
}
