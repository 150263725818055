.navbar {
  position: sticky;
  font-family: "Roboto", sans-serif;
  top: 0;
  height: 61px;
  background-color: #fff;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-wrap: nowrap;
  z-index: 2;
}

/*! LOGO */
.logo {
  margin: 0 10px;
  filter: drop-shadow(10px 10px 7px #bbb);
}

.logo:hover {
  cursor: pointer;
  filter: drop-shadow(10px 10px 7px rgb(246, 188, 244));
  transition: 0.5s all;
}

.logo:active {
  filter: none;
}

.menu {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  z-index: 1;
}

/*! SOL MENÜ */
.solMenu {
  display: flex;
  font-weight: 500;
  text-align: center;
  align-items: center;

  .menuItem,
  .dgr {
    width: 100%;
    cursor: pointer;
  }

  .menuItem a,
  .menuDiger .menuItem a,
  .dgr {
    width: 100%;
    display: inline-block;
    padding: 12px 20px;
    color: #777;
    letter-spacing: 1.5px;
    text-shadow: 10px 10px 7px #aaa;
  }

  .menuItem:hover a,
  .menuItem a.active {
    color: #ff00fd;
    transition: 0.5s all;
    text-shadow: 10px 10px 7px #ef9ce9;
  }

  .menuItem:active a {
    text-shadow: none;
  }

  .dgr:hover .menuDiger {
    display: block;
    transition: 1s all;
  }
  .dgr:hover {
    color: #ff00fd;
    text-shadow: none;
    transition: 0.5s all;
  }

  .menuDiger {
    position: absolute;
    display: none;
    transition: 1s all;
    text-align: left;
    margin-top: 12px;
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: 2px 2px 5px #aaa;
    z-index: 1;
  }
}

/*! SAĞ MENÜ */
.sagMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

/*! BURGER */
.burger {
  display: none;
  cursor: pointer;
  border: 1px solid #d4fbff;
  box-shadow: 0px 0px 15px rgb(170, 221, 255), 2px 2px 1px #333;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 10px 20px 10px 10px;
}

#brgBtn {
  display: none;
}

.burger:hover {
  cursor: pointer;
  background-color: rgb(238, 251, 255);
  transition: 0.5s all;
}
.burger:active {
  border: 1px solid #aaa;
  box-shadow: 0px 0px 5px #aaa;
}

.burger .line1,
.burger .line2,
.burger .line3 {
  width: 24px;
  height: 3px;
  background-color: #f00;
  box-shadow: 0px 0px 15px #faa, 2px 2px 1px #333;
  margin: 4px 0;
}

/*! SİZE */
@media (width <= 795px) {
  .dgr:hover .menuDiger {
    left: 0;
    width: 100%;
    text-align: center;
  }

  .menu {
    position: absolute;
    display: none;
    flex-direction: column-reverse;
    align-items: center;
    background-color: #fff;
    width: 100%;
    top: 61px;
    right: 0;
    left: 0;
  }

  .solMenu {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 100%;
    right: 0;
    left: 0;
  }
  .sagMenu {
    flex-wrap: wrap;
  }
  .burger {
    display: block;
  }

  #brgBtn:checked ~ .menu {
    display: flex;
    transition: 1s all;
  }
}
@media (795px < width <= calc(1180px + 100px)) {
  .menu {
    justify-content: center;
  }
  .solMenu {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 100%;
    top: 61px;
    right: 0;
    left: 0;
  }

  .dgr:hover .menuDiger {
    left: 0;
    width: 100%;
    text-align: center;
  }

  .burger {
    display: block;
  }

  #brgBtn:checked ~ .menu > .solMenu {
    display: flex;
    transition: 1s all;
  }
}
